import { useTranslation } from 'next-i18next';
import React from 'react';
import styled from 'styled-components';
import { BsButtonWithIcon } from '@hotelplan/core.fusion.bs-button-with-icon';
import { E_BUTTON_TYPE } from '@hotelplan/style.hotelplan-style';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';

interface IFiltersButtonProps {
  onClick: () => void;
  isSticky?: boolean;
}

const FiltersButtonWrapper = styled(BsButtonWithIcon)(
  sx2CssThemeFn({
    fontSize: 2,
    display: 'flex',
    padding: 0,
    mr: 3,
    width: '48px',
    height: '48px',
    '.icon': {
      width: '24px',
      height: '24px',
    },
    '.filters-button-text': {
      display: 'none',
    },
  })
);

const FiltersButtonWrapperSticky = styled(BsButtonWithIcon)(
  sx2CssThemeFn({
    fontSize: 2,
    borderRadius: '0px',
    display: 'flex',
    alignItems: 'center',
    px: '12px',
    mr: 2,
    border: '1px solid',
    borderColor: 'borderColor',
    backgroundColor: 'white',
    height: '40px',
    '.icon': {
      backgroundColor: 'white',
      width: '20px',
      height: '20px',
      mr: 2,
    },
    '.filters-button-text': {
      backgroundColor: 'white',
    },
  })
);

const FiltersButton: React.FC<React.PropsWithChildren<IFiltersButtonProps>> = ({
  onClick,
  isSticky,
}) => {
  const [t] = useTranslation('filters');

  const FiltersButtonWrapperVariant = isSticky
    ? FiltersButtonWrapperSticky
    : FiltersButtonWrapper;
  return (
    <FiltersButtonWrapperVariant
      icon={{ name: isSticky ? 'filter-black' : 'filter' }}
      className="filter-button"
      type="button"
      data-id="openSearchFilter"
      variant={
        isSticky
          ? E_BUTTON_TYPE.ICON_BUTTON
          : E_BUTTON_TYPE.CIRCLE_BUTTON_SECONDARY
      }
      onClick={onClick}
    >
      <span className="filters-button-text">{t('filters.button')}</span>
    </FiltersButtonWrapperVariant>
  );
};

export default FiltersButton;
