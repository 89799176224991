import { TravelType } from '@hotelplan/graphql.types';
import { useFeatureToggle } from '@hotelplan/libs.feature-toggle';
import { FeatureList } from 'config/pageConfig.constants';

const DESTINATIONS_SEPARATOR = ',';
const TRAVEL_TYPE_TO_FEATURE_TOGGLE_NAME_MAP = new Map<TravelType, string>([
  [TravelType.Package, FeatureList.TOP_DESTINATIONS_AUTOSUGGEST_PACKAGE],
  [TravelType.Hotel, FeatureList.TOP_DESTINATIONS_AUTOSUGGEST_HOTEL],
]);

const useTopDestinationAutocompleteIds = (travelType: TravelType) => {
  const { getVariant } = useFeatureToggle();
  const featureToggleName = TRAVEL_TYPE_TO_FEATURE_TOGGLE_NAME_MAP.get(
    travelType
  );

  if (!featureToggleName) {
    return [];
  }

  const topDestinationsVariant = getVariant(featureToggleName);

  if (
    !topDestinationsVariant?.enabled ||
    typeof topDestinationsVariant?.payload?.type !== 'string'
  ) {
    return [];
  }

  return (topDestinationsVariant.payload.value as string)
    .split(DESTINATIONS_SEPARATOR)
    .map(item => {
      return item.trim();
    });
};

export default useTopDestinationAutocompleteIds;
