import React, { useEffect } from 'react';
import { useGetGeoInformationQuery } from 'graphql/geo/GetGeoInformation.generated';

const TopDestinationGeoNameQueryComponent: React.FC<{
  id: string;
  onLoad(id: string, name: string | null): void;
}> = ({ id, onLoad }) => {
  const { data, loading, error } = useGetGeoInformationQuery({
    variables: { id },
  });

  useEffect(() => {
    if (!loading && data?.geo?.information?.name?.length) {
      onLoad(id, data?.geo?.information?.name);
    } else if (!loading && (error || !data?.geo?.information?.name?.length)) {
      onLoad(id, null);
    }
  }, [loading, data, error]);

  return null;
};

export default TopDestinationGeoNameQueryComponent;
