import { useTranslation } from 'next-i18next';
import React, { useState } from 'react';
import styled from 'styled-components';
import { TravelType } from '@hotelplan/graphql.types';
import { useMemoryPagination } from '@hotelplan/libs.hooks';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import TopDestinationGeoNameQueryComponent from './TopDestinationGeoNameQueryComponent';
import TopDestinationItem from './TopDestinationItem';
import useTopDestinationAutocompleteIds from './useTopDestinationAutocompleteIds';

const DESTINATIONS_PER_PAGE = 5;

const TopDestinationShowMoreLink = styled.a(
  ({ theme: { space, colors, FONT_SIZE } }) =>
    sx2CssThemeFn({
      ...FONT_SIZE.LINK_LARGE,
      display: 'block',
      padding: `${space[2]} 0`,
      textDecoration: 'underline',
      textDecorationColor: colors.linkUnderlineColor,
      textUnderlineOffset: '6px',
      color: colors.defaultText,
      transition: 'none',
      transform: 'translate(0)',
      '.icon': {
        width: space[3],
        height: space[3],
        marginLeft: space[2],
        verticalAlign: 'middle',
      },
      '&:hover,&:focus': {
        color: colors.defaultText,
        textDecorationColor: colors.defaultText,
        transform: 'scale(1)',
      },
    })
);

interface ITopDestinationAutocompleteSuggestionsProps {
  firstPageCount: number;
  travelType: TravelType;
}

const TopDestinationAutocompleteSuggestions: React.FC<ITopDestinationAutocompleteSuggestionsProps> = ({
  firstPageCount,
  travelType,
}) => {
  const [geoNameMap, setGeoNameMap] = useState(new Map<string, string>());
  const destinationIds = useTopDestinationAutocompleteIds(travelType);
  const { items, nextPage, hasMore } = useMemoryPagination<string>(
    Array.from(geoNameMap.keys())
      .filter(id => geoNameMap.get(id) !== null)
      .sort((a, b) => {
        return destinationIds.indexOf(a) - destinationIds.indexOf(b);
      }),
    {
      perPage: DESTINATIONS_PER_PAGE,
      firstPageCount,
      enable: !!destinationIds.length,
    }
  );
  const [t] = useTranslation('common');

  const onLoad = (id: string, name: string | null) => {
    geoNameMap.set(id, name);
    setGeoNameMap(new Map(geoNameMap));
  };

  return (
    <>
      {geoNameMap.size < destinationIds.length &&
        destinationIds.map(id => {
          return (
            <TopDestinationGeoNameQueryComponent
              key={`top-destination-query-${id}`}
              id={id}
              onLoad={onLoad}
            />
          );
        })}
      {geoNameMap.size === destinationIds.length && (
        <div>
          {items.map(id => {
            return (
              <TopDestinationItem
                items={[{ id, name: geoNameMap.get(id) }]}
                name={geoNameMap.get(id)}
                key={`top-destination-${id}`}
              />
            );
          })}
          {hasMore && (
            <TopDestinationShowMoreLink
              href="#"
              onClick={e => {
                e.preventDefault();
                nextPage();
              }}
            >
              {t('load.more')}
            </TopDestinationShowMoreLink>
          )}
        </div>
      )}
    </>
  );
};

export default TopDestinationAutocompleteSuggestions;
