import React from 'react';
import { WhiteBoxModal } from '@hotelplan/components.common.modals';

interface IFiltersModalProps extends React.PropsWithChildren<{}> {
  isModalShowed: boolean;
  onClose: () => void;
}

const FiltersModal: React.FC<IFiltersModalProps> = ({
  isModalShowed,
  onClose,
  children,
}) => {
  return (
    <WhiteBoxModal
      name="searchFilter"
      show={isModalShowed}
      fullScreen={true}
      onClose={onClose}
      title={null}
    >
      {children}
    </WhiteBoxModal>
  );
};

export default FiltersModal;
