import { ICanonicalTravelDestination } from '@hotelplan/components.common.travel-destination';
import { getPageById } from '@hotelplan/libs.context.object-id';
import { TravelDestinationType } from '@hotelplan/platform-graphql-api';

const CONTINENT_SHORTCUT = 'k';
const COUNTRY_GROUP_SHORTCUT = 'g';
const COUNTRY_SHORTCUT = 'c';
const REGION_SHORTCUT = 'r';
const DESTINATION_SHORTCUT = 'd';
const RESORT_SHORTCUT = 'f';

export const mapGeoObjectIdAndNameToTravelDestination = (
  id: string,
  name: string
) => {
  const page = getPageById(id);
  const map = new Map<string, TravelDestinationType>([
    [CONTINENT_SHORTCUT, TravelDestinationType.Continent],
    [COUNTRY_GROUP_SHORTCUT, TravelDestinationType.CountryGroup],
    [COUNTRY_SHORTCUT, TravelDestinationType.Country],
    [REGION_SHORTCUT, TravelDestinationType.Region],
    [DESTINATION_SHORTCUT, TravelDestinationType.Destination],
    [RESORT_SHORTCUT, TravelDestinationType.Resort],
  ]);

  if (page?.objectType && map.has(page.objectType)) {
    return {
      id,
      name,
      type: map.get(page.objectType),
      canonicalName: name,
    } as ICanonicalTravelDestination;
  }

  return null;
};
