import type * as Types from 'types/types';

import type { DocumentNode } from 'graphql';
import { HistoryUnionResponseFragmentDoc } from './GetRecentSearchesHistory.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/libs.context.req-ctx';
const defaultOptions = {} as const;
export type GetHistoryByTravelTypeQueryVariables = Types.Exact<{
  context?: Types.InputMaybe<Types.RequestContextInput>;
  travelType: Types.TravelType;
  pageNumber?: Types.InputMaybe<Types.Scalars['Int']>;
  resultsPerPage?: Types.InputMaybe<Types.Scalars['Int']>;
}>;

export type GetHistoryByTravelTypeQuery = {
  __typename?: 'Query';
  history: {
    __typename?: 'HistoryContext';
    recentSearchesByTravelTypeWithPage: {
      __typename?: 'HistoryUnionResponse';
      recordUnions?: Array<
        | {
            __typename?: 'HistoryFlightRecord';
            travelType: Types.TravelType;
            flightType: Types.FlightType;
            link: {
              __typename?: 'InternalLink';
              caption?: string | null;
              uri: string;
              targetPageType: Types.PageType;
            };
            travellers: {
              __typename?: 'Travellers';
              adults: number;
              childrenDobs?: Array<string> | null;
            };
            flightPartitions?: Array<{
              __typename?: 'FlightPartition';
              flightClass: Types.FlightClass;
              departureAirport?: {
                __typename?: 'Airport';
                iata?: string | null;
                name?: string | null;
              } | null;
              arrivalAirport?: {
                __typename?: 'Airport';
                iata?: string | null;
                name?: string | null;
              } | null;
              travelPeriod: {
                __typename?: 'ExactTravelPeriod';
                returnDate: string;
                departureDate: string;
              };
            }> | null;
          }
        | {
            __typename?: 'HistorySrlRecord';
            rooms: number;
            travelType: Types.TravelType;
            link: {
              __typename?: 'InternalLink';
              caption?: string | null;
              uri: string;
              targetPageType: Types.PageType;
            };
            period: {
              __typename?: 'TravelPeriodComponent';
              type: Types.SearchPeriodType;
              exactPeriod: {
                __typename?: 'ExactTravelPeriod';
                departureDate: string;
                returnDate: string;
              };
              flexiblePeriod: {
                __typename?: 'FlexibleTravelPeriod';
                departureDate: string;
                maxDuration: number;
                minDuration: number;
                returnDate: string;
              };
            };
            travellers: {
              __typename?: 'Travellers';
              adults: number;
              childrenDobs?: Array<string> | null;
            };
            destinations?: Array<{
              __typename?: 'TravelDestination';
              id: string;
              name: string;
              type: Types.TravelDestinationType;
              mythosCode?: string | null;
              location?: {
                __typename?: 'GeoLocation';
                airport?: {
                  __typename?: 'Airport';
                  iata?: string | null;
                  name?: string | null;
                } | null;
                continent?: {
                  __typename?: 'GeoObject';
                  id: string;
                  name: string;
                  type: Types.GeoType;
                } | null;
                countryGroup?: {
                  __typename?: 'GeoObject';
                  id: string;
                  name: string;
                  type: Types.GeoType;
                } | null;
                country?: {
                  __typename?: 'GeoObject';
                  id: string;
                  name: string;
                  type: Types.GeoType;
                } | null;
                region?: {
                  __typename?: 'GeoObject';
                  id: string;
                  name: string;
                  type: Types.GeoType;
                } | null;
                destination?: {
                  __typename?: 'GeoObject';
                  id: string;
                  name: string;
                  type: Types.GeoType;
                } | null;
                resort?: {
                  __typename?: 'GeoObject';
                  id: string;
                  name: string;
                  type: Types.GeoType;
                } | null;
              } | null;
            }> | null;
          }
      > | null;
      page: {
        __typename?: 'Page';
        pageNumber: number;
        resultsTotal?: number | null;
        resultsPerPage?: number | null;
      };
    };
  };
};

export const GetHistoryByTravelTypeDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetHistoryByTravelType' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'context' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'RequestContextInput' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'travelType' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'TravelType' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pageNumber' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          defaultValue: { kind: 'IntValue', value: '0' },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'resultsPerPage' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          defaultValue: { kind: 'IntValue', value: '5' },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'history' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'context' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'context' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'recentSearchesByTravelTypeWithPage',
                  },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'travelType' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'travelType' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'page' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'pageNumber' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'pageNumber' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'resultsPerPage' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'resultsPerPage' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'historyUnionResponse' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...HistoryUnionResponseFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;

/**
 * __useGetHistoryByTravelTypeQuery__
 *
 * To run a query within a React component, call `useGetHistoryByTravelTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHistoryByTravelTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHistoryByTravelTypeQuery({
 *   variables: {
 *      context: // value for 'context'
 *      travelType: // value for 'travelType'
 *      pageNumber: // value for 'pageNumber'
 *      resultsPerPage: // value for 'resultsPerPage'
 *   },
 * });
 */
export function useGetHistoryByTravelTypeQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetHistoryByTravelTypeQuery,
    GetHistoryByTravelTypeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetHistoryByTravelTypeQuery,
    GetHistoryByTravelTypeQueryVariables
  >(GetHistoryByTravelTypeDocument, options);
}
export function useGetHistoryByTravelTypeLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetHistoryByTravelTypeQuery,
    GetHistoryByTravelTypeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetHistoryByTravelTypeQuery,
    GetHistoryByTravelTypeQueryVariables
  >(GetHistoryByTravelTypeDocument, options);
}
export type GetHistoryByTravelTypeQueryHookResult = ReturnType<
  typeof useGetHistoryByTravelTypeQuery
>;
export type GetHistoryByTravelTypeLazyQueryHookResult = ReturnType<
  typeof useGetHistoryByTravelTypeLazyQuery
>;
export type GetHistoryByTravelTypeQueryResult = Apollo.QueryResult<
  GetHistoryByTravelTypeQuery,
  GetHistoryByTravelTypeQueryVariables
>;
