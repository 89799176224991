import { useTranslation } from 'next-i18next';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  AutocompleteOptionLoader,
  IAutocompleteCountStateItem,
  useAutocompleteContext,
  useAutocompleteCountContext,
} from '@hotelplan/components.common.autocomplete';
import { ICanonicalTravelDestination } from '@hotelplan/components.common.travel-destination';
import { TravelDestinationType } from '@hotelplan/platform-graphql-api';
import { TTravelDestination } from 'components/domain/travel-destination/TravelDestination.types';
import { mapGeoObjectIdAndNameToTravelDestination } from './TopDestination.utils';

interface ITopDestinationItem {
  items: { id: string; name: string }[];
  name: string;
}

const TopDestinationLink = styled.a(({ theme: { space } }) => ({
  display: 'flex',
  padding: `${space[2]} 0`,
  justifyItems: 'center',
}));

const TopDestinationLinkTitle = styled.span({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  flexGrow: 1,
});

const TopDestinationLinkCount = styled.div({
  display: 'flex',
  alignItems: 'center',
});

const TopDestinationItem: React.FC<ITopDestinationItem> = ({ items, name }) => {
  const travelDestinations = items.map(item =>
    mapGeoObjectIdAndNameToTravelDestination(item.id, item.name)
  );
  const autocompleteContext = useAutocompleteContext<TTravelDestination>();
  const autocompleteCountContext = useAutocompleteCountContext<ICanonicalTravelDestination>();
  const [countItem, setCountItem] = useState<
    IAutocompleteCountStateItem<ICanonicalTravelDestination>
  >(null);
  const [t] = useTranslation('common');

  useEffect(() => {
    const item = autocompleteCountContext.items.find(contextItem => {
      return (
        contextItem.items.length === items.length &&
        _.intersection(
          contextItem.items.map(i => i.id),
          items.map(i => i.id)
        ).length === items.length
      );
    });

    if (countItem === null && !item) {
      autocompleteCountContext.putItem({
        id: items.map(i => i.id).join(';'),
        items: travelDestinations,
      });
    } else if (item) {
      setCountItem(item);
    }
  }, [autocompleteCountContext.items, countItem]);

  if (!travelDestinations.filter(i => i === null).length) {
    return (
      <TopDestinationLink
        href={'#'}
        onClick={e => {
          e.preventDefault();

          if (travelDestinations.length) {
            travelDestinations.forEach(i => {
              autocompleteContext.selectItem(i);
            });
          } else {
            autocompleteContext.selectItem({
              name: t('allDestinations'),
              canonicalName: t('allDestinations'),
              type: TravelDestinationType.Destination,
            });
          }
        }}
      >
        <TopDestinationLinkTitle>{name}</TopDestinationLinkTitle>
        <TopDestinationLinkCount>
          {countItem?.loading && <AutocompleteOptionLoader />}
          {!countItem?.loading &&
            typeof countItem?.count === 'number' &&
            countItem?.count}
        </TopDestinationLinkCount>
      </TopDestinationLink>
    );
  }

  return null;
};

export default TopDestinationItem;
