import type * as Types from 'types/types';

import type { DocumentNode } from 'graphql';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/libs.context.req-ctx';
const defaultOptions = {} as const;
export type GetGeoInformationQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
  context?: Types.InputMaybe<Types.RequestContextInput>;
}>;

export type GetGeoInformationQuery = {
  __typename?: 'Query';
  geo: {
    __typename?: 'GeoContext';
    information: { __typename?: 'GeoInfoComponent'; name: string };
  };
};

export const GetGeoInformationDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetGeoInformation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'context' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'RequestContextInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'geo' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'context' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'context' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'information' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode;

/**
 * __useGetGeoInformationQuery__
 *
 * To run a query within a React component, call `useGetGeoInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGeoInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGeoInformationQuery({
 *   variables: {
 *      id: // value for 'id'
 *      context: // value for 'context'
 *   },
 * });
 */
export function useGetGeoInformationQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetGeoInformationQuery,
    GetGeoInformationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetGeoInformationQuery,
    GetGeoInformationQueryVariables
  >(GetGeoInformationDocument, options);
}
export function useGetGeoInformationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetGeoInformationQuery,
    GetGeoInformationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetGeoInformationQuery,
    GetGeoInformationQueryVariables
  >(GetGeoInformationDocument, options);
}
export type GetGeoInformationQueryHookResult = ReturnType<
  typeof useGetGeoInformationQuery
>;
export type GetGeoInformationLazyQueryHookResult = ReturnType<
  typeof useGetGeoInformationLazyQuery
>;
export type GetGeoInformationQueryResult = Apollo.QueryResult<
  GetGeoInformationQuery,
  GetGeoInformationQueryVariables
>;
