import { useTranslation } from 'next-i18next';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useAutocompleteContext } from '@hotelplan/components.common.autocomplete';
import { Icon } from '@hotelplan/components.common.icon';
import { TravelType } from '@hotelplan/graphql.types';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { ISearchControlState } from 'components/domain/searchControl/SearchControl.types';
import { useAddHistoryItem } from 'components/domain/searchControl/useAddHistoryItem';
import TopDestinationAutocompleteSuggestions from 'components/domain/top-destination-autocomplete-suggestions/TopDestinationAutocompleteSuggestions';
import TopDestinationItem from 'components/domain/top-destination-autocomplete-suggestions/TopDestinationItem';
import useTopDestinationAutocompleteIds from 'components/domain/top-destination-autocomplete-suggestions/useTopDestinationAutocompleteIds';
import { useGetHistoryByTravelTypeQuery } from 'graphql/history/GetHistoryByTravelType.generated';
import SearchHistorySkeleton from './SearchHistory.skeleton';
import { HistoryType } from './SearchHistory.types';
import SearchHistoryGroup from './SearchHistoryGroup';

const SearchHistoryTopDestinationWrapper = styled.div(
  sx2CssThemeFn(
    {
      display: 'flex',
      '.historyGroup': {
        mb: 0,
        '> a': {
          mb: [2, 2],
        },
        '> a:first-child': {
          mt: [2, 2],
        },
        '.icon': {
          width: '24px',
          height: '24px',
          mr: '14px',
          mt: 2,
        },
        '.search-history-paginator': {
          display: 'none',
        },
      },
    },
    ({ theme }) => theme.autocomplete.tagBlock,
    {
      paddingTop: ['none', 'none'],
      border: ['none', 'none'],
      maxHeight: ['100%', '540px'],
    }
  )
);

const SuggestionListBox = styled.ul.attrs({
  role: `listbox`,
})(({ theme }) => theme.autocomplete.suggestionListStyles, {
  padding: 0,
  border: 'none',
});

const GroupHeader = styled.div(({ theme: { space, FONT_SIZE } }) =>
  sx2CssThemeFn({
    height: '40px',
    flexShrink: 0,
    paddingLeft: space[3],
    paddingRight: space[3],
    marginLeft: `-${space[3]}`,
    marginRight: `-${space[3]}`,
    display: 'flex',
    alignItems: 'center',
    ...FONT_SIZE.MEDIUM,
    backgroundColor: '#F4F4F4',
    '.icon': {
      width: '16px',
      height: '16px',
    },
  })
);

const GroupIcon = styled(Icon)({});

const GroupTitle = styled.span(({ theme: { space } }) => ({
  paddingLeft: space[2],
}));

interface ISearchHistoryAutocompleteSuggestions {
  travelType: TravelType;
  onHistoryChange?(shown: boolean): void;
  onTopDestinationsChange?(shown: boolean): void;
}

const HISTORY_RESULTS_PER_PAGE = 3;
const TOP_DESTINATION_MAX_PER_PAGE = 5;

const SearchHistoryTopDestinationComboSuggestions: React.FC<ISearchHistoryAutocompleteSuggestions> = ({
  travelType,
  onHistoryChange,
  onTopDestinationsChange,
}) => {
  const ctx = useAutocompleteContext();
  const addHistoryItem = useAddHistoryItem();
  const [t] = useTranslation('common');

  const { data, loading } = useGetHistoryByTravelTypeQuery({
    variables: {
      travelType,
      pageNumber: 0,
      resultsPerPage: HISTORY_RESULTS_PER_PAGE,
    },
  });
  const historyItems =
    data?.history.recentSearchesByTravelTypeWithPage.recordUnions;

  const topDestinationIds = useTopDestinationAutocompleteIds(travelType);

  const showHistorySuggestions =
    !ctx.loading &&
    ctx.suggestions.length === 0 &&
    ctx.value === '' &&
    !loading &&
    historyItems &&
    historyItems.length !== 0;

  const topDestinationFirstPageCount =
    TOP_DESTINATION_MAX_PER_PAGE - historyItems?.length || 0;

  const showTopDestinations =
    topDestinationIds.length !== 0 &&
    ctx.suggestions.length === 0 &&
    ctx.selectedItems.length === 0 &&
    topDestinationFirstPageCount > 0;

  const onHistoryItemClick = (searchState?: ISearchControlState) => {
    if (searchState) {
      addHistoryItem(searchState);
    }
  };

  useEffect(() => {
    onTopDestinationsChange?.(showTopDestinations);
  }, [showTopDestinations]);

  useEffect(() => {
    onHistoryChange?.(showHistorySuggestions);
  }, [showHistorySuggestions]);

  return (
    <SearchHistoryTopDestinationWrapper>
      {loading && <SearchHistorySkeleton />}
      {!loading && (
        <>
          {showTopDestinations && (
            <div>
              <TopDestinationItem
                items={[]}
                name={t('allDestinations')}
                key={`top-destination-all-destinations`}
              />
            </div>
          )}
          {showHistorySuggestions && (
            <>
              <GroupHeader>
                <GroupIcon name={'history'} />
                <GroupTitle>{t('recentSearch.heading')}</GroupTitle>
              </GroupHeader>
              <SuggestionListBox>
                <SearchHistoryGroup
                  disableIcon
                  items={historyItems}
                  historyType={HistoryType.SEARCH}
                  onHistoryItemClick={onHistoryItemClick}
                />
              </SuggestionListBox>
            </>
          )}
          {showTopDestinations && (
            <>
              <GroupHeader>
                <GroupIcon name={'planet'} />
                <GroupTitle>{t('topDestinations')}</GroupTitle>
              </GroupHeader>
              <TopDestinationAutocompleteSuggestions
                travelType={travelType}
                firstPageCount={topDestinationFirstPageCount}
              />
            </>
          )}
        </>
      )}
    </SearchHistoryTopDestinationWrapper>
  );
};

export default SearchHistoryTopDestinationComboSuggestions;
